import type { Dispatch } from 'react';
import type { ValueOf } from 'lib/types';

import React, { createContext, useContext, useReducer, useMemo } from 'react';

export type GlobalStateContextData = {
  headerHeight: number;
  signupFormModal: {
    isOpen?: boolean;
  };
};

export type GlobalStateContextValue = GlobalStateContextData & {
  dispatch: Dispatch<GlobalStateContextAction>;
};

export type GlobalStateContextAction =
  | {
      type: 'update';
      key: keyof GlobalStateContextData;
      value: ValueOf<GlobalStateContextData>;
    }
  | { type: 'reset'; value: GlobalStateContextData };

export const DEFAULT_CONTEXT_VALUES: GlobalStateContextValue = {
  headerHeight: 98,
  signupFormModal: { isOpen: false },
  dispatch: () => null,
};

export const GlobalStateContext = createContext<GlobalStateContextValue>(
  DEFAULT_CONTEXT_VALUES,
);

export function useGlobalStateContext(): GlobalStateContextValue {
  return useContext(GlobalStateContext);
}

export const globalStateContextReducer = (
  state: GlobalStateContextData,
  action: GlobalStateContextAction,
): GlobalStateContextData => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case 'reset': {
      return { ...DEFAULT_CONTEXT_VALUES, ...action.value };
    }
  }
};

export default function GlobalStateProvider({
  children,
}: {
  children: React.ReactElement;
}): JSX.Element {
  const [state, dispatch] = useReducer(
    globalStateContextReducer,
    DEFAULT_CONTEXT_VALUES,
  );

  const contextValue = useMemo(
    () => ({ ...state, dispatch }),
    [state, dispatch],
  );

  return (
    <GlobalStateContext.Provider value={contextValue}>
      {children}
    </GlobalStateContext.Provider>
  );
}
