import Cookies from 'js-cookie';

export const POSTHOG_COOKIE_KEY = `ph_${process.env.NEXT_PUBLIC_POSTHOG_API_KEY}_posthog`;
export const POSTHOG_BOOTSTRAP_COOKIE_KEY = 'ph_bootstrapData';

export function getPosthogCookie(): string | undefined {
  return Cookies.get(POSTHOG_COOKIE_KEY);
}

export function getPosthogBootstrapCookie(): string | undefined {
  return Cookies.get(POSTHOG_BOOTSTRAP_COOKIE_KEY);
}
