export const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY;
export const POSTHOG_API_HOST = process.env.NEXT_PUBLIC_POSTHOG_API_HOST;
export const POSTHOG_PERSONAL_API_KEY = process.env.POSTHOG_PERSONAL_API_KEY;
export const POSTHOG_PROJECT_ID = process.env.POSTHOG_PROJECT_ID;

type Variant = 'control' | 'test';

type Experiment = {
  id: string;
  path: string;
  slug: string;
  variant?: {
    slug?: {
      [key in Variant]: string;
    };
  };
  metadata?: {
    state?: string;
    test: {
      [key: string]: string | number;
    }[];
  };
};

type VariantPath = {
  params: {
    slug: string[];
    locale: string;
  };
};

export const experiments: Experiment[] = [
  {
    id: 'new-mc-page-pennsylvania',
    path: '/medical-card/pennsylvania',
    slug: 'pennsylvania',
    variant: {
      slug: {
        test: '/medical-card/pennsylvania',
        control: '/medical-card/pennsylvania-old',
      },
    },
  },
  {
    id: 'new-mc-page-ohio',
    path: '/medical-card/ohio',
    slug: 'ohio',
    variant: {
      slug: {
        test: '/medical-card/ohio',
        control: '/medical-card/ohio-old',
      },
    },
  },
  {
    id: 'new-mc-page-massachusetts',
    path: '/medical-card/massachusetts',
    slug: 'massachusetts',
    variant: {
      slug: {
        test: '/medical-card/massachusetts',
        control: '/medical-card/massachusetts-old',
      },
    },
  },
  {
    id: 'new-mc-page-louisiana',
    path: '/medical-card/louisiana',
    slug: 'louisiana',
    variant: {
      slug: {
        test: '/medical-card/louisiana',
        control: '/medical-card/louisiana-old',
      },
    },
  },
  {
    id: 'new-mc-page-virginia',
    path: '/medical-card/virginia',
    slug: 'virginia',
    variant: {
      slug: {
        test: '/medical-card/virginia',
        control: '/medical-card/virginia-old',
      },
    },
  },
];

export function getExperimentByKey(key: string) {
  return experiments.find(experiment => experiment[key] === key);
}

export function getExperimentSlugByVariant(params: string[], variant: Variant) {
  const slug = `/${params.join('/')}`;
  const experiment = experiments.find(experiment => {
    return experiment.path === slug;
  });

  if (!experiment?.variant?.slug[variant]) {
    throw new Error(
      `Error in 'getStaticProps': Experiment not found for ${slug}`,
    );
  }

  return experiment.variant.slug[variant];
}

export async function getExperimentVariantPaths(pages): Promise<VariantPath[]> {
  const phExperiments = await api.getExperiments();
  let variantPaths = [];

  for (const phExperiment of phExperiments) {
    const experiment = experiments.find(
      experiment => experiment.id === phExperiment.feature_flag_key,
    );

    if (experiment) {
      const { feature_flag_variants } = phExperiment.parameters;
      const page = pages.find(page => page.slug === experiment.slug);
      const { language, parent, slug } = page;

      variantPaths = feature_flag_variants.map(({ key }: { key: string }) => {
        return {
          params: {
            slug: [...(parent?.node ? [parent?.node?.slug] : []), slug, key], // e.g. ['medical-card', 'california', 'test']
            locale: language?.slug,
          },
        };
      });
    }
  }

  return variantPaths;
}

/**
 * Fetch wrapper for the Posthog API
 */
export async function posthog(
  path: string,
  method: string,
  { data }: { data?: any } = {},
): Promise<any> {
  const url = new URL(POSTHOG_API_HOST);
  url.pathname = path;

  if (!POSTHOG_PERSONAL_API_KEY) {
    throw new Error('No Posthog API key was provided');
  }

  const res = await fetch(url.href, {
    method,
    headers: {
      Authorization: `Bearer ${POSTHOG_PERSONAL_API_KEY}`,
    },
    body: JSON.stringify(data),
  });

  const resData = await res.json();

  if (res.ok) {
    return resData;
  } else {
    throw new Error(
      `Posthog failed with (${res.status}): ${
        typeof resData === 'string' ? resData : JSON.stringify(resData, null, 2)
      }`,
    );
  }
}

const api = {
  // https://posthog.com/docs/api/experiments#get-api-projects-project_id-experiments
  async getExperiments() {
    const experiments = await posthog(
      `/api/projects/${POSTHOG_PROJECT_ID}/experiments`,
      'GET',
    );

    if (!experiments.results.length) {
      return null;
    }

    return experiments.results.map(result => result);
  },
  // https://posthog.com/docs/api/experiments#get-api-projects-project_id-experiments
  async getExperiment(experimentId: number) {
    const experiment = await posthog(
      `/api/projects/${POSTHOG_PROJECT_ID}/experiments/${experimentId}`,
      'GET',
    );

    if (!experiment) {
      return null;
    }

    return experiment;
  },
  async getFeatureFlags(distinctId: string) {
    const posthogRes = await fetch(`${POSTHOG_API_HOST}/decide?v=3`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_key: POSTHOG_API_KEY,
        distinct_id: distinctId,
      }),
    });
    const data = await posthogRes.json();

    return data;
  },
};

export default api;
