export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

declare global {
  interface Window {
    gtag?: any;
    ga?: any;
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string): void => {
  if (typeof window !== 'undefined') {
    window?.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

export const clientId = (): string => {
  const cookie = {};
  document.cookie.split(';').forEach(function (el) {
    const splitCookie = el.split('=');
    const key = splitCookie[0].trim();
    const value = splitCookie[1];
    cookie[key] = value;
  });

  if (!cookie['_ga']) {
    return null;
  }

  return cookie['_ga'].substring(6);
};
